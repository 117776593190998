import React from 'react';
import '../App.css';

const HeaderLogo = (props) => {

    return (
        <img src='wedding-rings-152336_1280.png' className="App-logo" alt="logo" />
    );

}

export default HeaderLogo;