import MainComponent from './Main/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginComponent from './Login/Login';
import { useState } from 'react';
import React from 'react';
import Profile from 'Main/Profile';
import HomePage from 'Main/HomePage';
import Admin from 'Main/Admin';
import Timeline from 'Main/Timeline';
import ComingSoonPage from 'Comingsoon';
import QAPage from 'Main/QA';
import Registry from 'Main/Registry';
import GuestBookComponent from 'Main/GuestBook';

const App = props => {

    const [ userInfo, setUserInfo ] = useState(null);

    return (

        <div className="App">
            <header className="App-header">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" 
                            element={ 
                            userInfo ? 
                            <MainComponent 
                                userInfo = {userInfo}
                                setUserInfo={setUserInfo}
                                /> 
                            : 
                            <LoginComponent
                                userInfo = {userInfo}
                                setUserInfo={setUserInfo}
                            />
                            } 
                            errorElement={<ComingSoonPage />}
                            >
                            <Route path="/"
                                element={
                                    <HomePage 
                                        userInfo={userInfo}
                                        setUserInfo={setUserInfo}
                                    />
                                }
                            >
                            </Route>
                            <Route path="/profile"
                                element={
                                    <Profile 
                                        userInfo={userInfo}
                                        setUserInfo={setUserInfo}
                                    />
                                }
                            >
                            </Route>
                            <Route path="/timeline"
                                element={
                                    <Timeline 
                                        userInfo={userInfo}
                                        setUserInfo={setUserInfo}
                                    />
                                }
                            >
                            </Route>
                            <Route path="/qa"
                                element={
                                    <QAPage 
                                        userInfo={userInfo}
                                        setUserInfo={setUserInfo}
                                    />
                                }
                            >
                            </Route>
                            {(userInfo?.isAdmin || userInfo?.canSeeGuestList) && <Route path="/admin"
                                element={
                                    <Admin 
                                        userInfo={userInfo}
                                        setUserInfo={setUserInfo}
                                    />
                                }
                            >
                            </Route>
                            }
                            <Route path="/registry"
                                element={
                                    <Registry 
                                        userInfo={userInfo}
                                        setUserInfo={setUserInfo}
                                    />
                                }
                            >
                            </Route>
                            <Route path="/guestbook"
                                element={
                                    <GuestBookComponent 
                                        userInfo={userInfo}
                                    />
                                }
                            >
                            </Route>
                            <Route path="*"
                                element={
                                    <ComingSoonPage />
                                }
                            >
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>

            </header>
        </div>
    );
}

export default App;
