import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import * as services from '../services';
import * as utils from '../Utils';
import { useEffect } from 'react';

const RequestInviteModal = (props) => {

    //google captcha
    //client site key: 6Le1EkskAAAAAObu33g-YVHmDiOK31UTPp7LgO9s
    //secret key: 6Le1EkskAAAAADHAY7i92vfrakViGDL-oMYmN8su

    const { open, setOpen } = props;
    const [ requestName, setRequestName ] = useState("");
    const [ requestEmail, setRequestEmail ] = useState("");
    const [ requestPhone, setRequestPhone ] = useState("");
    const [ reCaptchaToken, setReCaptchaToken ] = useState(null);

    const [ requestResponse, setRequestResponse ] = useState(null);


    const captchaRef = useRef(null);

    const recaptchaKeys = utils.getReCaptchaKeys();
    
    useEffect(() => {
        if (captchaRef && captchaRef.current) {
            captchaRef.current.reset();
        }
    }, []);

    const handleSave = async () => {

        const response = await services.requestInviteCode({
            requestName: requestName,
            requestEmail: requestEmail,
            requestPhone: requestPhone,
            reCaptchaToken: reCaptchaToken,
            isProd: (process.env.NODE_ENV === "production"),
        });

        console.log(response);

        if (response && response.data) {
            
        }
        captchaRef.current.reset();
        setOpen(false);
    }
    
    const handleCancel = () => {
        captchaRef.current.reset();
        setOpen(false);
    }
    
    const handleClose = (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        captchaRef.current.reset();
        
        setOpen(false);
    }

    const handleCaptureChange = (token) => {
        if (token) setReCaptchaToken(token);
    }

    const isFormValid = () => {
        return reCaptchaToken && requestName && requestEmail;
    }

    return (
        <div>
            <Dialog 
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>Request Invite</DialogTitle>
                <DialogContentText 
                     margin={"0px 15px 15px 15px"}
                >
                    Enter your contact information to request an invite.<br/>
                    Only known guests and their plus ones will get an invite code.
                </DialogContentText>
                <Grid container spacing={2} margin={"0px 5px 0px 5px"}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            autoFocus
                            required
                            id="name"
                            label="Name"
                            fullWidth
                            value={requestName}
                            onChange={(e) => setRequestName(e.target.value)}
                        >
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            autoFocus
                            required
                            id="email"
                            label="Email"
                            fullWidth
                            value={requestEmail}
                            onChange={(e) => setRequestEmail(e.target.value)}
                        >
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            autoFocus
                            
                            id="phone"
                            label="Phone"
                            fullWidth
                            value={requestPhone}
                            onChange={(e) => setRequestPhone(e.target.value)}
                        >
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ReCAPTCHA 
                            sitekey={recaptchaKeys.siteKey}
                            onChange={handleCaptureChange}
                            ref={captchaRef}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSave} disabled={!isFormValid()}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )    
}

export default RequestInviteModal;