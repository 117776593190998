
export const rsvpStatusToDisplay = (userInfo) => {

    if (userInfo && userInfo.rsvpStatus) {
        switch(userInfo.rsvpStatus) {
            case "Y":
                return "Yes!";
            case "N":
                return "No";
            case "U":
                return "Not sure yet";
            default:
                return "Unknown";
        }
    }
    return "Unknown";
}

export const rsvpFoodToDisplay = (userInfo) => {

    if (userInfo && userInfo.rsvpStatusFoodOption) {
        switch(userInfo.rsvpStatusFoodOption) {
            case "BEEF":
                return "Beef Meal";
            case "CHICKEN":
                return "Chicken Meal";
            case "VEG":
                return "Vegatarian Meal";
            default:
                return "Unknown";
        }
    }
    return "Unknown";
}

export const doLogout = (setUserInfo, navigate) => {
    setUserInfo(null);
    localStorage.removeItem("rememberInvite");
    navigate('/', {replace: true});
}

export const getReCaptchaKeys = () => {
    if (process.env.NODE_ENV === "production") {
        return {
            secretKey: process.env.REACT_APP_SECRET_KEY,
            siteKey: process.env.REACT_APP_SITE_KEY,
        }
    } else {
        return {
            secretKey: process.env.REACT_APP_TEST_SECRET_KEY,
            siteKey: process.env.REACT_APP_TEST_SITE_KEY,
        }
    }
}