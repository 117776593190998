import { Dialog, Grid, IconButton } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

const QRCodeModal = (props) => {

    const {open, qrCodeProfile, setOpen} = props;
    const handleClose = async (e, reason) => {
        setOpen(false);
    }

    return (
        <div>
            <Dialog 
                open={open}
                onClose={handleClose}
                fullScreen
            >
                <div>
                    <Grid margin={10}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            X
                        </IconButton>
                        <div>
                            {qrCodeProfile?.name}                
                        </div>
                        <div>Visit our personal Wedding Website</div>
                        <div>Invite Code: {qrCodeProfile?.inviteCode}</div>
                        <div>https://banselmota.com/?urlInviteCode={qrCodeProfile?.inviteCode}</div>

                        <div>
                            <QRCode
                                //value="yes"
                                size={128}
                                value={`https://banselmota.com/?urlInviteCode=${qrCodeProfile?.inviteCode}`}
                            >
                            </QRCode>
                        </div>
                        <div>
                            Use your phone camera to scan the above QR Code!
                        </div>
                    </Grid>

                </div>
            </Dialog>
        </div>
    )
}

export default QRCodeModal;



