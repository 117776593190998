import { Delete, Edit, Save } from "@mui/icons-material";
import { Grid, IconButton, Paper, Stack, TextField, Typography, styled } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import * as services from "services";

const GuestBookComponent = (props) => {

    const { userInfo } = props;
    const [ guestBook, setGuestBook ] = useState(null);
    const [ newGuestBookEntry, setNewGuestBookEntry ] = useState("");
    const [ editId, setEditId ] = useState(null);


    const buildGuestBook = async () => {
        let data = await services.getGuestBook();
        data = data?.data?.filter((x) => {return !x.isDeleted});



        setGuestBook(data);
    }

    useEffect(() => {
        buildGuestBook();
    }, []);

    const handleNewEntry = (e) => {
        console.log(e.target.value);
        
        setNewGuestBookEntry(e.target.value);
    }
   
    const saveNewMessage = async () => {

        if (newGuestBookEntry) {

            let response;

            if (editId) {
                response = await services.editGuestBook(userInfo, editId, newGuestBookEntry);
            } else {
                response = await services.addGuestBook(userInfo, newGuestBookEntry);
            }

            console.log(response);

            if (response?.data?.status == "200") {
                setNewGuestBookEntry("");
                setEditId(null);
                await buildGuestBook();
            }
        }

    }

    const editGuestBook = async (e) => {
        const entry = guestBook.filter((x) => x.id === e.currentTarget.value)[0];

        if (entry && entry.message) {
            setNewGuestBookEntry(entry.message);
            setEditId(entry.id);
        }
    }

    const deleteGuestBook = async (e) => {
        const response = await services.deleteGuestBook(userInfo, e.currentTarget.value);
        console.log(response);
        if (response?.data?.status === 200) {
            buildGuestBook();
        } else {
            window.alert("Error deleting entry");
        }
    }
    
    const GuestBookEntry = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
      
    return (

        <div style={{margin:"0px 45px 0px 45px"}}>
            
            <Typography>
                Guest Book
            </Typography>

            <Stack spacing={2}>
                {guestBook && guestBook.map ((entry) => {
                    return (
                        <GuestBookEntry>
                            <Typography>
                                {entry.date} - {entry?.user?.name} - {entry.message}
                            </Typography>
                            {(userInfo.isAdmin || 
                                userInfo.canWriteAnnouncements) && 
                                    <span style={{margin: "-5px 0px 0px 12px"}}>
                                        <IconButton
                                            size="small"
                                                onClick={editGuestBook}
                                                value={entry.id}>                                                                                                
                                        <Edit> 
                                        </Edit>
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                                onClick={deleteGuestBook}
                                                value={entry.id}>                                                                                                
                                        <Delete> 
                                        </Delete>
                                        </IconButton>
                                    </span>
                            }
                        </GuestBookEntry>
                    );
                })}
            </Stack>

            <Grid container spacing={2} margin={"5px 0px 5px 0px"}>
                <Grid item xs={12}>
                    <TextField
                        sx={{backgroundColor: "white"}}
                        autoFocus
                        required
                        id="message"
                        label="Guest Book Message"
                        fullWidth
                        value={newGuestBookEntry}
                        onChange={handleNewEntry}
                        inputProps={{ maxLength: 100 }}
                    >
                        
                    </TextField>
                </Grid>
                <IconButton
                    onClick={saveNewMessage}>
                    <Save>

                    </Save>
                    Save
                </IconButton>

            </Grid>
        </div>
    );

}

export default GuestBookComponent;
