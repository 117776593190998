import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Link, ToggleButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import * as utils from '../Utils';
import * as announcementsData from './announcementsData';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Add, Delete } from "@mui/icons-material";
import AnnouncementComponent from "./Announcement";

const HomePage = (props) => {

    const { userInfo, setUserInfo } = props;
    
    const navigate = useNavigate();
    const daysTilWedding = () => {
        const weddingDate = new Date(2023, 3, 22, 16, 30);
        const todayDate = new Date();
        const dateDiff = weddingDate - todayDate;

        let days = 0;
        if (dateDiff > 0) {
            days = Math.trunc(dateDiff / 1000 / 60 / 60 / 24);
            const hours = ((dateDiff / 1000 / 60 / 60 / 24) - days) * 24;
            const hoursTrunc = Math.trunc(hours);

            const minutes = (hours - hoursTrunc) * 60;
            const minutesTrunc = Math.trunc(minutes);
            const seconds = Math.trunc((minutes - minutesTrunc) * 60);
            days = `${days} Days ${hoursTrunc.toFixed(0)} hours ${minutesTrunc.toFixed(0)} minutes ${seconds.toFixed(0)} seconds`;

            // days = (dateDiff / 1000).toFixed();
            // console.log(todayDate);
            // console.log(days);
        }
        return days;
    }

    const [ daysTillWeddingValue, setDaysTillWeddingValue ] = useState(daysTilWedding());
    useEffect(() => {
        const timer = setInterval(() => setDaysTillWeddingValue(() => daysTilWedding()), 1000);
        
        return () => clearInterval(timer);
      }, []);

    return (
        <div>
            {/* Announcement Div */}
            <AnnouncementComponent
                userInfo={userInfo}
                >

            </AnnouncementComponent>
            <h1>{ userInfo.name }</h1>
            {(!userInfo.rsvpStatus || (userInfo.rsvpStatus !== 'Y' && userInfo.rsvpStatus !== 'N')) &&
                <p>
                RSVP: No <br/>Regretfully declined
                </p>
            }
            {userInfo.rsvpStatus === 'Y' &&            
                <>
                    <p>
                        RSVP: Yes, we will be attending!
                    </p>
                    <p>
                        Table: {userInfo.tableNumber === 'head' ? 'Sweetheart Table' : 
                                userInfo.tableNumber === 'weddingparty' ? 'Wedding Party Table' :
                                userInfo.tableNumber ? userInfo.tableNumber : 'Not Assigned'}
                    </p>
                </>
            }
            {userInfo.rsvpStatus === 'N' &&
                <p>
                    RSVP: Regretfully declined
                </p>
            }
            <p> 
                <Link
                    component="button"
                    variant="body1"
                    sx={{margin: 1}}
                    onClick={() => { navigate("guestbook") }}
                >
                    <span style={{fontSize:"large", fontWeight:"bold"}}>Please Sign our Online Wedding Guest Book</span>
                </Link>
            </p>
            <Box 
                sx={{
                    backgroundImage: "url('2023-03-07_Wedding_Invitation_website.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'block',
                    height: '550px',

                }}>
            </Box>
            <p><strong>{daysTillWeddingValue}</strong></p>
            <p>until our wedding!</p>
            <p>Date: April 22nd, 2023 4:30PM</p>
            <p>Click 
                <Link
                    component="button"
                    variant="body1"
                    sx={{margin: 1}}
                    onClick={() => { navigate("profile") }}
                >
                    Profile
                </Link>
                to Review and Update your Profile and RSVP!
            </p>
            <p>Wedding Registry, Hotel Information can be found in our Q & A section in the Menu!</p>
            <p>Photos coming soon!</p>
            <p>Bookmark our website and check back often for announcements!</p>
            <a href="https://www.theknot.com/us/manoela-mota-and-ujj-bansel-apr-2023">
                Our "The Knot" Wedding Website
            </a>
            <br></br>
            <Link 
                component="button"
                variant="body2"
                onClick={() => {
                    utils.doLogout(setUserInfo, navigate);
                }}
            >
                Log out
            </Link>
        </div>
    )
}

export default HomePage;
