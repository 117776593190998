import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Link, TextField, ToggleButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import * as utils from '../Utils';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import * as services from '../services';

const AnnouncementComponent = (props) => {

    const { userInfo } = props;
    
    const [ announcements, setAnnouncements] = useState(null);
    const [ showAddAnnouncement, setShowAddAnnouncement ] = useState(false);
    const [ newAnnouncementMessage, setNewAnnouncementMessage ] = useState ("");
    const [ editAnnouncementId, setEditAnnouncementId ] = useState(null);

    const buildAnnouncements = async () => {
            
        let data = await getAnnouncements();

        let previouslyViewedAnnouncements = localStorage.getItem("announcements");
        if (previouslyViewedAnnouncements && typeof (previouslyViewedAnnouncements) === 'string') {
            
            previouslyViewedAnnouncements = previouslyViewedAnnouncements.split(',');
            data = data.filter((x) => { 
                return !previouslyViewedAnnouncements.includes(x.id.toString()); 
            });
        }
        setAnnouncements(data);
    }
    
    const getAnnouncements = async () => {
        const announcements = await services.getAnnouncements();
        console.log(announcements);
        let announcementToDisplay = announcements?.data;

        if (announcementToDisplay && Array.isArray(announcementToDisplay)) {
            announcementToDisplay = announcementToDisplay.filter((x) => !x.isDeleted);
        }
        return announcementToDisplay;
    }

    useEffect(() => {
        buildAnnouncements();
    }, []);

    const handleReadAnnouncements = (hide) => {
        const allAnnouncements = announcements;
        if (hide && allAnnouncements && allAnnouncements.length) {
            const currentIds = allAnnouncements.reduce((ids, announcement) => {
                ids.push(announcement.id);
                return ids;
            }, []);
            localStorage.setItem("announcements", currentIds.join());
        } else {
            localStorage.removeItem("announcements");
        }
        buildAnnouncements();
    }

    const addAnnouncement = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(e);
        setNewAnnouncementMessage("");
        setShowAddAnnouncement(true);
    }

    const saveNewAnnouncement = async (e) => {
        let response = null;

        if (editAnnouncementId) {

            response = await services.editAnnouncement(userInfo, { id: editAnnouncementId, message: newAnnouncementMessage});

            setShowAddAnnouncement(false);
            setEditAnnouncementId(null);
        } else {
            response = await services.addAnnouncement(userInfo, newAnnouncementMessage);
        }

        if (response?.data?.status === 200) {
            setShowAddAnnouncement(false);
            buildAnnouncements();
        } else {
            window.alert("Error adding announcement");
        }
    }

    const editAnnouncement = async (e) => {
        const announcement = announcements.filter((x) => x.id === e.currentTarget.value);
        if (announcement && announcement.length === 1) {
            setNewAnnouncementMessage(announcement[0].message);
            setEditAnnouncementId(announcement[0].id);
            setShowAddAnnouncement(true);
        }
    }

    const deleteAnnouncement = async (e) => {
        const response = await services.deleteAnnouncement(userInfo, e.currentTarget.value);
        console.log(response);
        if (response?.data?.status === 200) {
            setShowAddAnnouncement(false);
            buildAnnouncements();
        } else {
            window.alert("Error deleting announcement");
        }
    }

    return (
        <div>
            {/* Announcement Div */}
            <div style={{display: "inline-block"}}>
                <Accordion sx={{backgroundColor:"floralwhite"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography 
                            sx={{
                                fontSize: "1.25rem", 
                                color:"red", 
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <NotificationsIcon></NotificationsIcon>
                            Announcements!
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {(userInfo.isAdmin || 
                        userInfo.canWriteAnnouncements) && 
                        <span style={{margin: "-5px 0px 0px 12px"}}>
                            <IconButton
                                size="small"
                                onClick={addAnnouncement}>                                                                                                
                                <Add> 
                                </Add>
                            </IconButton>
                        </span>
                    }
                    {showAddAnnouncement ?
                    (
                        <div>
                            <TextField
                                label="Message"
                                value={newAnnouncementMessage}
                                onChange={(e) => {setNewAnnouncementMessage(e.target.value)}}
                                >

                            </TextField>
                            <IconButton
                                onClick={saveNewAnnouncement}>
                                <Save>

                                </Save>
                            </IconButton>
                            <IconButton
                                onClick={()=>setShowAddAnnouncement(false)}>
                                <Cancel>

                                </Cancel>
                            </IconButton>
                        </div>
                    ) 
                    :

                    (announcements && announcements.length) ? 
                        (
                        <>
                        {announcements.sort((a,b) => { return new Date(b.date).getTime() - new Date(a.date).getTime() }).map((a) => {
                            return (
                            <div>
                                <Typography key={a.id}
                                    sx={{display:"flex"}}>
                                        {a.date} - {a.message} 
                                        {(userInfo.isAdmin || 
                                        userInfo.canWriteAnnouncements) && 
                                            <span style={{margin: "-5px 0px 0px 12px"}}>
                                                <IconButton
                                                    size="small"
                                                     onClick={editAnnouncement}
                                                     value={a.id}>                                                                                                
                                                <Edit> 
                                                </Edit>
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                     onClick={deleteAnnouncement}
                                                     value={a.id}>                                                                                                
                                                <Delete> 
                                                </Delete>
                                                </IconButton>
                                            </span>
                                        
                                        }
                                
                                </Typography>
                                
                            </div>
                        )})
                        }
                        <div>
                            <Button onClick={() => handleReadAnnouncements(true)}>
                                Mark Announcements as Read 
                            </Button>
                        </div>
                        </>
                        )
                        :
                        (
                        <>
                        <Typography
                            sx={{display:"flex"}}>
                                No new announcements.
                        </Typography>
                        <div>
                            <Button onClick={() => handleReadAnnouncements(false)}>
                                See all Old Announcements 
                            </Button>
                        </div>
                        </>
                        )    
                    }                    
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default AnnouncementComponent;