import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import * as utils from '../Utils';

const HeaderBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { userInfo, setUserInfo } = props;
  const navigate = useNavigate();
  const pages = [
    { page: 'Home', label: 'Home', alwaysShow: false },
    // { page: 'Venue', label: 'Venue', alwaysShow: false },
    { page: 'QA', label: 'Q and A', alwaysShow: false}, 
    { page: 'Registry', label: 'Registry', alwaysShow: false},
    { page: 'Photos', label: 'Photos', alwaysShow: false},
    { page: 'Guestbook', label: 'Guest Book', alwaysShow: false},
    // { page: 'Chatroom', label: 'Chatroom', alwaysShow: false},
    { page: 'Profile', label: 'Update RSVP/Profile', alwaysShow: true },
    { page: 'Timeline', label: 'Timeline', alwaysShow: false },
  ];

  if (userInfo && (userInfo.isAdmin || userInfo.canSeeGuestList)) {
    pages.push({ page: 'Admin', label: 'Admin', alwaysShow: false});
  }
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const goToPage = (page) => {

    if(page) {
        const navPage = page.toLowerCase();
        if (navPage === 'home') {
            navigate('/');
        } else {
            navigate(`${navPage}`);
        }
    }
  }

  const handleCloseNavMenu = (pageLabel) => {
    setAnchorElNav(null);
    if (pageLabel) {
      goToPage(pageLabel);
    }
  };

  const handleLogOut = () => {
    utils.doLogout(setUserInfo, navigate);
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon /> <Typography sx={{ my: 0, color: 'white', display: 'block' }}>MENU</Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => 
                <MenuItem key={page.page} onClick={() => handleCloseNavMenu(page.page)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              )  
              }
            </Menu>
            {/* Always show (mobile) */}
            {pages.filter((p) => p.alwaysShow).map((page) => (
              
              <Button
                key={page.page}
                onClick={() => handleCloseNavMenu(page.page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          {/* nonmobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.page}
                onClick={() => handleCloseNavMenu(page.page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Button
                key='logout'
                onClick={handleLogOut}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Log out
            </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderBar;
