import React, { useState } from "react";
import { DataGrid, gridNumberComparator } from "@mui/x-data-grid";
import * as services from '../services';
import { useEffect } from "react";
import { Box, Button, Grid} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import QRCodeModal from "./QRCodeModal";
import ProfileEditModal from "./ProfileEditModal";
import RsvpStatusUpdateModal from "./RsvpStatusUpdateModal";

const Admin = (props) => {

    const { userInfo } = props;

    const [profiles, setProfiles] = useState([]);
    const [inviteRequests, setInviteRequests] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [openQRCodeModal, setOpenQRCodeModal] = useState(false);
    const [qrCodeProfile, setQrCodeProfile] = useState(null);
    const [tableSetup, setTableSetup] = useState([]);

    const [openProfileEdit, setOpenProfileEdit ] = useState(false);
    const [openRsvpEdit, setOpenRsvpEdit] = useState(false);
    const [currentProfile, setCurrentProfile] = useState(null);

    const generateNewInviteCode = () => {
        const validChars = '23456789ABCDEFGHJKLMNPQRSTUWXYZ';
        let newCode = '';
        while (newCode === '' ) {
            while (newCode.length < 6) {
                newCode += validChars.charAt(getRndInt(0, validChars.length - 1));
            }
//newCode = 'ABCDEF';
            if (profiles.find((p) => p.inviteCode === newCode)) {
//                newCode = 'BLAH';
                newCode = '';
            }
//            console.log(newCode);
        }
        return newCode;
    }

    const getRndInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    const getNewRow = () => {
        return {
            isNew: true,
            rsvpStatus: "",
            inviteCode: generateNewInviteCode(),
            userType: "",
            address: {
            zip: "",
            country: "",
            state: "FL",
            city: "",
            line2: "",
            line1: ""
            },
            isAdmin: false,
            email: "",
            id: uuidv4(),
            name: "",
            phone: "",
        }
    }

    useEffect(() => {
        loadProfiles();
        loadInviteRequests();
        setOpenQRCodeModal(false);

    }, [])

    useEffect(() => {

        setupTables();
    }, [profiles])

    const setupTables = () => {

        const _tableSetup = [];
        const tableNumbers = ["head","weddingparty","1","2","3","4","5","6","7","8","9","10","11"];

        if (profiles && profiles.length > 0) {
            let _profiles = [...profiles];
            console.log(_profiles);

            tableNumbers.forEach((t) => {
                let table = {
                    tableNumber: t,
                    names: [], 
                }
                _profiles.sort((a, b) => {return (a.groupNumber - b.groupNumber)}).reduce((table, p) => {
                    if (p.tableNumber == table.tableNumber) {
                        table.names.push({name: p.name, groupNumber: p.groupNumber});
                    }
                    return table;
                }, table);
                _tableSetup.push (table);
            })

            console.log(_tableSetup);
        }

        setTableSetup(_tableSetup);
    }

    const TableComponent = (props) => {

        const {table} = props;

        return (
            <Grid item xs={6} md={2}>
                <Box boxShadow={3}
                    sx={{color: 'black'}}
                >
                    <div>
                        Table - {table.tableNumber} ({table.names.length}):<br/>
                        {table.names && table.names.map ((name) => {
                            return (
                                <><span style={{fontSize:"medium"}}>{name.name} ({name.groupNumber})</span><br/></>
                                );
                        })}
                    </div>
                </Box>
            </Grid>
        )
    }

    let loadingProfiles = false;
    let loadingInviteRequests = false;
    
    const loadProfiles = async () => {
        if (!loadingProfiles) {
            loadingProfiles = true;
            const _profiles = await services.getProfiles(userInfo);

            loadingProfiles = false;
            if (_profiles && _profiles.data) {
                setProfiles(_profiles.data.map((profile) => {
                    return {...profile, isNew: false,
                    addressLine1: profile.address.line1,
                    addressLine2: profile.address.line2,
                    addressCity: profile.address.city,
                    addressState: profile.address.state,
                    addressZip: profile.address.zip,
                    addressCountry: profile.address.country,
                };
                }));
            } else {
                setProfiles([]);
            }
        }
    }

    const loadInviteRequests = async () => {
        if (!loadingInviteRequests) {
            loadingInviteRequests = true;
            const _inviteRequests = await services.getInviteRequests(userInfo);

            loadingInviteRequests = false;
            if (_inviteRequests && _inviteRequests.data) {
                setInviteRequests(_inviteRequests.data.map((request) => {
                    return {...request};
                }));
            } else {
                setInviteRequests([]);
            }
        }
    }

    const handleQRCodeClick = (profile_id) => {

        const currentProfile = profiles.find((p) => p.id === profile_id);

        if (currentProfile) {
            setQrCodeProfile({...currentProfile});
            setOpenQRCodeModal(true);
        }
    }

    const handleViewProfileClick = (profile_id, action) => {
        const currentProfile = profiles.find((p) => p.id === profile_id);
        if (currentProfile) {
            setCurrentProfile(currentProfile);
            switch(action) {
                case 'P':
                    setOpenProfileEdit(true);
                    break;
                case 'R':
                    setOpenRsvpEdit(true);
                    break;
                default:
            }
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'name', headerName: 'Name', width: 150, editable: true,},
        { field: 'groupNumber', headerName: 'Group', width: 75, editable: true, 
        sortComparator: gridNumberComparator},
        { field: 'tableNumber', headerName: 'Table', width: 75, editable: true, },
        { field: 'userType', headerName: 'Party Type', width: 100, editable: true },
        { field: 'rsvpStatus', headerName: 'RSVP', width: 100, editable: true, },
        { field: 'rsvpStatusFoodOption', headerName: 'Food', width: 150, editable: false, },
        { field: 'rsvpStatusNote', headerName: 'Note', width: 150, editable: false, 
            renderCell: (params) => {
                return params?.row?.rsvpStatusNote || params?.row?.rsvpStatusReason || "";
            }
        },
        { field: 'lastLogin', headerName: 'Last Login', width: 195, editable: false, 
            renderCell: (params) => {
                const lastLogin = params?.row?.lastLogin;
                if (lastLogin) {
                    return new Date(lastLogin).toLocaleString();
                }
                return "";
            }
        },
        { field: 'actions', headerName: 'Actions', width: 100, editable: false,
            renderCell: (params) => {
                return <span><Button style={{width:'20px', minWidth:'1px'}}
                            onClick={(e) => {
                                handleViewProfileClick(e.target.value, 'P');
                            }}
                            value={params?.row?.id}
                            >P
                        </Button>
                        |
                        <Button style={{width:'20px', minWidth:'1px'}}
                            onClick={(e) => {
                                handleViewProfileClick(e.target.value, 'R');
                            }}
                            value={params?.row?.id}
                            >R
                        </Button>
                        </span>
            },
        },
        { field: 'inviteCode', headerName: 'Invite Code', width: 100, editable: true, 
            // renderCell: (params) => {
            //     return <strong>{params?.row?.inviteCode}</strong>;
            // },
            // onClick: (e) => {
            //     console.log("clicked cell");
            //     console.log(e);
            // }
        },
        { field: 'inviteCodeQR', headerName: 'QR', width: 50, editable: false, 
            renderCell: (params) => {
                return <Button onClick={(e) => {
                    console.log("clicked cell");
                    console.log(e.target.value); 
                    handleQRCodeClick(e.target.value);   
                }

                }
                value={params?.row?.id}
                >
                    {params?.row?.inviteCode}
                </Button>;
            },
        },
        { field: 'email', headerName: 'Email', width: 150, editable: true, },
        { field: 'phone', headerName: 'Phone', width: 150, editable: true, },

        { field: 'addressLine1', headerName: 'Address Line 1', width: 150, editable: true,
            // renderCell: (params) => {
            //     return params?.row?.address?.line1;
            // },
            valueSetter: (params) => {
                if (params && params.row && params.row.address) {
                    params.row.address.line1 = params.value;
                }
                return { ...params.row, addressLine1: params.value };
            },
        },
        { field: 'addressLine2', headerName: 'Address Line 2', width: 150, editable: true,
            valueSetter: (params) => {
                if (params && params.row && params.row.address) {
                    params.row.address.line2 = params.value;
                }
                return { ...params.row, addressLine2: params.value };
            },

        },
        { field: 'addressCity', headerName: 'City', width: 125, editable: true, 
            valueSetter: (params) => {
                if (params && params.row && params.row.address) {
                    params.row.address.city = params.value;
                }
                return { ...params.row, addressCity: params.value };
            },
        },        
        { field: 'addressState', headerName: 'State', width: 50, editable: true, 
            valueSetter: (params) => {
                if (params && params.row && params.row.address) {
                    params.row.address.state = params.value;
                }
                return { ...params.row, addressState: params.value };
            },
        },
        { field: 'addressZip', headerName: 'Zip', width: 100, editable: true, 
            valueSetter: (params) => {
                if (params && params.row && params.row.address) {
                    params.row.address.zip = params.value;
                }
                return { ...params.row, addressZip: params.value };
            },
        },
        { field: 'addressCountry', headerName: 'Country', width: 100, editable: true, 
            valueSetter: (params) => {
                if (params && params.row && params.row.address) {
                    params.row.address.country = params.value;
                }
                return { ...params.row, addressCountry: params.value };
            },
        },
    ]

    const addNewRowRequest = () => {
        if (!profiles.find((x) => x.isNew)) {
            
            const rowToAdd = {...getNewRow()}
            setProfiles(profiles.concat(rowToAdd));
        }
    }

    const deleteRowRequest = () => {
        console.log("delete - todo");
        console.log(selectionModel);
    }

    const doRowUpdate = async (newRow, oldRow) => {
        const result = await services.updateProfileAdmin(userInfo, newRow, oldRow);
        if (result?.data?.status === "Updated") {
            if (newRow?.isNew) {
                addNewRowToProfile(newRow);
                newRow.isNew = false;

            }
        }
        return newRow;
    }

    const addNewRowToProfile = (row) => {
//test
        if(row && row.isNew) {
            const newProfiles = profiles.filter((p) => !p.isNew);
            setProfiles(newProfiles.concat({...row, isNew:false}));
        }
    }

    const refreshRequest = () => {
        loadProfiles();
        loadInviteRequests();
    }

    const getGuests = () => {
        return profiles ? profiles.filter((x) => !x.isVendor) : [];
    }

    const getTotalRsvpNoCount = () => {
        const guests = getGuests();
        return guests ? guests.filter((x) => x.rsvpStatus === "N").length : 0;
    }
    const getTotalPotentialGuests = () => {
        const guests = getGuests();
        if (guests) {
            return guests.length - getTotalRsvpNoCount();
        }
        return 0;
    }

    const getTotalFoodCount = (option) => {
        const guests = getGuests();
        if (guests) {
            return (guests.filter((x) => x.rsvpStatusFoodOption === option).length);
        }

        return 0;
    }

    return (
        <div>
            <div>
                <Grid container
                    margin={'5px 5px 5px 15px'}
                    spacing={2}>
                    <Grid item xs={6} md={2}>
                        <Box boxShadow={3}
                            sx={{color: 'black'}}
                        >
                            <div>
                                Invite Requests:<br/>
                                {inviteRequests.length}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box boxShadow={3}
                            sx={{color: 'black'}}
                        >
                            <div>
                                Total Potential Guests:<br/>
                                {getTotalPotentialGuests()}/{getGuests().length};
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box boxShadow={3}
                            sx={{color: 'black'}}
                        >
                            <div>
                                Total RSVP Yes:<br/>
                                {getGuests().filter((x) => x.rsvpStatus === "Y").length}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box boxShadow={3}
                            sx={{color: 'black'}}
                        >
                            <div>
                                Total RSVP No:<br/>
                                {getTotalRsvpNoCount()}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box boxShadow={3}
                            sx={{color: 'black'}}
                        >
                            <div>
                                Total Logged In:<br/>
                                {profiles.filter((x) => x.lastLogin).length}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box boxShadow={3}
                            sx={{color: 'black'}}
                        >
                            <div>
                                Food Counts:<br/>
                                Beef: {getTotalFoodCount('BEEF')} Chicken: {getTotalFoodCount('CHICKEN')} Veg: {getTotalFoodCount('VEG')}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div>
            <Grid container
                    margin={'5px 5px 5px 15px'}
                    spacing={2}>
                    
                    {tableSetup && tableSetup.map((table) => {
                        return (
                        <TableComponent
                            table={table}>

                        </TableComponent>
                        )
                        }
                    )}

                </Grid>
            </div>
            {userInfo.isAdmin && (
                <>
                <Button onClick={addNewRowRequest}>Add New</Button>
                <Button onClick={deleteRowRequest}>Delete</Button>
                </>
            )}
            <Button onClick={refreshRequest}>Refresh</Button>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ height: 400, width: '80%', display:'inline-block' }}>
                        <DataGrid
                            rows={profiles}
                            columns={columns}
                            //pageSize={100}
                            //rowsPerPageOptions={[5, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}

                            experimentalFeatures={{ newEditingApi: true } }
                            editMode={userInfo.isAdmin ? "row" : null}
                            processRowUpdate={doRowUpdate}

                            //Doesn't work
                            initialState={{
                                filter: {
                                  filterModel: {
                                    items: [{ field: 'rsvpStatus', operator: 'equals', value: 'Y' }],
                                  },
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <QRCodeModal
                open={openQRCodeModal}
                qrCodeProfile={qrCodeProfile}
                setOpen={setOpenQRCodeModal}
                >

            </QRCodeModal>
            <ProfileEditModal
                open={openProfileEdit}
                setOpen={setOpenProfileEdit}
                userInfo={currentProfile}
                setUserInfo={setCurrentProfile}>
            </ProfileEditModal>
            <RsvpStatusUpdateModal
                open={openRsvpEdit}
                setOpen={setOpenRsvpEdit}
                userInfo={currentProfile}
                setUserInfo={setCurrentProfile}>
            </RsvpStatusUpdateModal>

        </div>
    );
}

export default Admin;
