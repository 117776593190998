import * as React from 'react';
import MUITimeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Typography } from "@mui/material";
import { Box } from '@mui/system';

const Timeline = (props) => {

    const timelineEvents = [
        { time: '4:30 pm', note: 'Wedding Ceremony at the Tree'},
        { time: '5:15 pm', note: 'Cocktail Hour at the Stables'},
        { time: '6:15 pm', note: 'Bridal Party Entrance and First Dance'},
        { time: '6:30 pm', note: 'Dinner at the Stables'},
        { time: '7:30 pm', note: 'Speeches'},
        { time: '7:45 pm', note: 'Cake!'},
        { time: '7:50 pm', note: 'Music and Dancing!'},
        { time: '9:45 pm', note: 'Bride and Groom Exit'},
        { time: '10:30 pm', note: 'Finish'},
    ]
    return (
        <>

            <Typography
                variant="h2"
                sx={{color: "black"}}
            >
                Timeline and Maps
            </Typography>
            <MUITimeline>
                {timelineEvents.map((t, i) => {
                    return (
                    <TimelineItem key={t.time}>
                        <TimelineOppositeContent color="text.secondary">
                            {t.time}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            { i !== timelineEvents.length - 1 && <TimelineConnector /> }
                        </TimelineSeparator>
                        <TimelineContent color="black">{t.note}</TimelineContent>
                    </TimelineItem>
                )})}
            </MUITimeline>
            <Box>
                <img
                    src="pebblehillmap.jpg"
                    alt="pebblehillmap"
                >
                </img>
            </Box>
        </>
    );
}

export default Timeline;