import Typography from '@mui/material/Typography';
import React from 'react';
import { useLocation } from 'react-router-dom';

const Copyright = (props) => {

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright Ujj Bansel © '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
      
}

export default Copyright;
