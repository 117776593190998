
const baseUrl = 'https://nvtkiqsmny5od4nlxe7cb7pvsm0gnuur.lambda-url.us-east-1.on.aws/';

export const doLogin = async (inviteCode) => {
    const url = `${baseUrl}?action=testInvite&code=${inviteCode}`
    let response = await fetch(url,
        { } );
    
    return await response.json();
}

export const updateProfile = async (userInfo) => {
    const url = `${baseUrl}?action=updateProfile`;
    const body = {
        inviteCode: userInfo.inviteCode,
        profileInfo: userInfo,
    }

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify(body),
        });

    return response.json();
}

export const getProfiles = async (userInfo) => {
    const url = `${baseUrl}?action=getProfiles`;
    // const body = {
    //     profileInfo: userInfo,
    // }

    let response = await fetch(url,
        {
            method: 'GET',
        });

    return response.json();
}

export const getInviteRequests = async (userInfo) => {
    const url = `${baseUrl}?action=getInviteRequests`;
    // const body = {
    //     profileInfo: userInfo,
    // }

    let response = await fetch(url,
        {
            method: 'GET',
        });

    return response.json();
}

export const updateProfileAdmin = async (userInfo, newRow, oldRow) => {
    const url = `${baseUrl}?action=updateProfileAdmin`;
    const body = {
        currentUser: userInfo,
        newProfileRow: newRow,
        oldProfileRow: oldRow,
    }

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify(body),
        });

    return response.json();
}

export const requestInviteCode = async (request) => {
    const url = `${baseUrl}?action=requestInviteCode`;
    const body = request;
    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify(body),
        });
    
    return response.json();
}

export const getAnnouncements = async () => {
    const url = `${baseUrl}?action=getAnnouncements`;

    let response = await fetch(url,
        {
            method: 'GET',
        });

    return response.json();
}

export const addAnnouncement = async (userInfo, message) => {
    const url = `${baseUrl}?action=addAnnouncement`;

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({currentUser: userInfo, announcementToAdd: message}),
        });

    return response.json();
}

export const editAnnouncement = async (userInfo, announcement) => {
    const url = `${baseUrl}?action=editAnnouncement`;

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({currentUser: userInfo, announcement: announcement}),
        });

    return response.json();

}

export const deleteAnnouncement = async (userInfo, id) => {
    const url = `${baseUrl}?action=deleteAnnouncement`;

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({currentUser: userInfo, announcementIdToDelete: id}),
        });

    return response.json();

}

export const getGuestBook = async () => {
    const url = `${baseUrl}?action=getGuestBook`;

    let response = await fetch(url,
        {
            method: 'GET',
        });

    return response.json();
}

export const addGuestBook = async (userInfo, message) => {
    const url = `${baseUrl}?action=addGuestBook`;

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({currentUser: userInfo, message: message}),
        });

    return response.json();
}

export const editGuestBook = async (userInfo, id, message) => {
    const url = `${baseUrl}?action=editGuestBook`;

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({currentUser: userInfo, guestbook: { user: userInfo, id: id, message: message}}),
        });

    return response.json();
}

export const deleteGuestBook = async (userInfo, id) => {
    const url = `${baseUrl}?action=deleteGuestBook`;

    let response = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({currentUser: userInfo, guestbook: { user: userInfo, id: id}}),
        });

    return response.json();
}
