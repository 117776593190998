import React from "react";

const { Typography } = require("@mui/material")

const Registry = (props) => {
    
    return (
        <>

            <Typography
                variant="h2"
                sx={{color: "black"}}
            >
                Registry Information
            </Typography>

            <Typography
                variant="body1"
                sx={{color: "black"}}
            >
                We have a registry set up for gifts.<br/>
                While your presence at our celebration is more than enough,<br/>
                we have a honeymoon fund and other gift items available to choose from. <br/><br/>
                If you are not sure what to choose and you want to give us a gift, <br/>
                consider buying us a drink or two via our honeymoon fund!
            </Typography>
            <a href='https://www.theknot.com/us/manoela-mota-and-ujj-bansel-apr-2023/registry' 
                target="_blank" rel="noopener noreferrer">Click here to go to our registry</a>
        </>
    )

}

export default Registry;