import React from "react";
import { Outlet, Route, Router, Routes } from "react-router-dom";
import HeaderBar from "./Header";
import HeaderLogo from "./HeaderLogo";
import HomePage from "./HomePage";
import Profile from "./Profile";

const MainComponent = props => {

    const {  userInfo, setUserInfo } = props;

    return (
        <div>
            <HeaderBar
                userInfo={userInfo}
                setUserInfo={setUserInfo}>
            </HeaderBar>
            <HeaderLogo></HeaderLogo>
            {/* <div style={{display:"block"}}> */}
                <Outlet/>
            {/* </div> */}
        </div>
    )
}

export default MainComponent;
