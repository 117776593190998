
export default function ComingSoonPage() {

  return (
    <div id="coming-soon-page">
      <h1>Coming Soon!</h1>
      <p>Sorry, this page is still under development.</p>
      <p>Check back soon as the website gets updated.</p>
    </div>
  );
}