import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import * as services from 'services';

const ProfileEditModal = (props) => {

    const { open, setOpen, userInfo, setUserInfo } = props;

    const [ editUserInfo, setEditUserInfo ] = useState({...userInfo});

    useEffect(() => {
        if (userInfo) {
            setEditUserInfo({...userInfo});
        }
    }, [userInfo]);

    const handleCancel = (e, reason) => {
        setOpen(false);
    }
    
    const handleClose = async (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        
        const response = await services.updateProfile(editUserInfo);
        const newUserInfo = {...response.data.updatedItem};

        setUserInfo(newUserInfo);
        setOpen(false);
    }

    const handleChange = (e) => {
        editUserInfo[e.target.id] = e.target.value;
        setEditUserInfo({...editUserInfo});
    }

    return (
        <div>
            <Dialog 
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>Profile Update</DialogTitle>
                <DialogContentText 
                     margin={"0px 15px 15px 15px"}
                >
                    Review your profile and update if needed.
                </DialogContentText>
                <Grid container spacing={2} margin={"0px 5px 0px 5px"}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            required
                            id="name"
                            label="Name"
                            fullWidth
                            value={editUserInfo.name}
                            onChange={handleChange}
                        >
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            required
                            id="email"
                            label="Email"
                            fullWidth
                            value={editUserInfo.email}
                            onChange={handleChange}
                        >
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            required
                            id="phone"
                            label="Phone"
                            fullWidth
                            value={editUserInfo.phone}
                            onChange={handleChange}
                        >
                            
                        </TextField>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleClose}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ProfileEditModal;