import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
//import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../Main/Copyright';
import { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import HeaderLogo from 'Main/HeaderLogo';
import Paper from '@mui/material/Paper';
import styles from './Login.css';
import * as services from 'services';
import RequestInviteModal from './RequestInviteModal';
import { CircularProgress } from '@mui/material';

const LoginComponent = props => {

    const { userInfo, setUserInfo } = props;
    const [ invalidLoginAttempt, setInvalidLoginAttempt ] = useState(false);
    const [ startLoggingIn, setStartLoggingIn ] = useState(false);
    const [ searchUrl, setSearchUrl ] = useSearchParams();
    const [ rememberInvite, setRememberInvite ] = useState(true);
    const [ openRequestInviteModal, setOpenRequestInviteModal] = useState(false);

    const doLogon = async (inviteCode) => {

        // const url = `https://nvtkiqsmny5od4nlxe7cb7pvsm0gnuur.lambda-url.us-east-1.on.aws/?action=testInvite&code=${inviteCode}`
        // let response = await fetch(url,
        //     { } );
        
        // let responseData = await response.json();

        const responseData = await services.doLogin(inviteCode);

        if (responseData && responseData.data && responseData.data.authenticated) {
            const _userInfo = responseData.data.data.Attributes;
            setUserInfo(_userInfo);
            if (rememberInvite) {
                localStorage.setItem("rememberInvite", inviteCode);
            }
        } else {
            setInvalidLoginAttempt(true);
            localStorage.removeItem("rememberInvite");
        }        
        setStartLoggingIn(false);
    }

    let urlInviteCode = null;
    urlInviteCode = searchUrl.get("urlInviteCode");
    
    if (!urlInviteCode) {
        urlInviteCode = localStorage.getItem("rememberInvite");
    }

    if (urlInviteCode && !startLoggingIn && !invalidLoginAttempt) {
        setStartLoggingIn(true);
        doLogon(urlInviteCode);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setInvalidLoginAttempt(false);
        setStartLoggingIn(true);
        const data = new FormData(event.currentTarget);

        const inviteCode = data.get('inviteCode');

        await doLogon(inviteCode);
    };

    const handleRequestInviteModal = () => {
        setOpenRequestInviteModal(true);
    }

    return (
        <div>
            <Paper classes={styles.LoginMain}
                variant='outlined'
            
                sx={{
                    display: 'inline-flex',
                    backgroundColor: 'floralwhite',
                    padding: '0px 32px 0px 32px',
                    margin: '16px',
                }}
            >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'floralwhite',
                        padding: '0px 8px 0px 8px',
                    }}
                    >

                    <HeaderLogo></HeaderLogo>

                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {startLoggingIn ? <span>Logging In...</span> : <span>Log In</span>}
                    </Typography>
                    {startLoggingIn ? 
                    <CircularProgress></CircularProgress>
                    :
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="inviteCode"
                            label="Invite Code"
                            id="inviteCode"
                            autoComplete="current-inviteCode"
                            sx={{
                                backgroundColor:'white',
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox 
                                checked={rememberInvite} 
                                onChange={(e) => setRememberInvite(!!e.target.checked)}
                                color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={startLoggingIn}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2"
                                 onClick={handleRequestInviteModal}>
                                    No Invite Code?  Click to Request One.
                                </Link>
                            </Grid>
                        </Grid>                        
                        { invalidLoginAttempt && (
                        <Typography component="h1" variant="h5">
                            Invalid Login.
                        </Typography>
                        )}
                    </Box>
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            </Paper>
            <RequestInviteModal
                open={openRequestInviteModal}
                setOpen={setOpenRequestInviteModal}
            >

            </RequestInviteModal>

        </div>
    );
}

export default LoginComponent;
