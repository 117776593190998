import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* eslint-disable no-multi-str */
const QAPage = (props) => {

    const qadata = [
        {
            id: 1,
            question: "How far is the venue from Tallahassee?", 
            answer: "From The Springhill suites by Marriot Tallahassee North it is 23 \
             miles 28 minutes (if driven by Ujj) and 23 (if driven by Mannie) \
             <br/> \
             <iframe src=\"https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d219692.8172635911!2d-84.32068086963272!3d30.64240370306819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x88ec58b771c883f3%3A0x3fda32075a6d330b!2sSpringHill%20Suites%20by%20Marriott%20Tallahassee%20North%2C%201481%20Timberlane%20Rd%2C%20Tallahassee%2C%20FL%2032312!3m2!1d30.5036067!2d-84.25257719999999!4m5!1s0x88edb2de76f3144f%3A0x474b05b4a8ec7641!2s1251%20U.S.%20319%2C%20Thomasville%2C%20GA!3m2!1d30.7813509!2d-84.0641234!5e0!3m2!1sen!2sus!4v1676593493880!5m2!1sen!2sus\" width=\"100%\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe> \
             ",
        },
        {
            id: 2,
            question: "What hotels are recommended?", 
            answer: "The closest hotels to the venue are: <br/> \
            <ul> \
            <li><a href=https://www.marriott.com/events/start.mi?id=1670863950963&key=GRP> \
            The Springhill suites by Marriott Tallahassee North <span style='color: red'>(Preferred)</span></a> <br/> \
            Group rate - $129/night - Mota-Bansel Wedding \
            </li> \
            <li><a href=https://www.marriott.com/events/start.mi?id=1670949650747&key=GRP> \
            The Marriott Courtyard - Tallahassee North </a><br/> \
            Group rate - $149/night - Mota-Bansel Wedding \
            </li> \
            </ul>\
            If you prefer to stay downtown: \
            <ul> \
            <li><a href=https://www.hilton.com/en/hotels/thlapdt-doubletree-tallahassee/?SEO_id=GMB-AMER-DH-THLAPDT&y_source=1_MTM3MjU0MC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3> \
            Double Tree \
            </a></li> \
            \
            <li><a href=https://www.marriott.com/en-us/hotels/tlhak-hotel-duval-autograph-collection/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0> \
            Hotel Duval \
            </a></li> \
            </ul>",
        },
        {
            id: 13,
            question: "Do you have a wedding registry?",
            answer: "We have a registry set up for gifts. \
            While your presence at our celebration is more than enough, \
            we have a honeymoon fund and other gift items available to choose from. <br/> \
            <a href='https://www.theknot.com/us/manoela-mota-and-ujj-bansel-apr-2023/registry' target=\"_blank\" rel=\"noopener noreferrer\">Click here to go to our registry</a>\
            "
        },
        {
            id: 3,
            question: "What restaurants are near the Tallahassee North Hotels?",
            answer: "\
            <ul> \
                <li>Sage is a very nice local restaurant (the vibe is more formal, date night, foodie) \
                <br/><a href=https://www.sagetallahassee.com/>https://www.sagetallahassee.com/</a> \
                </li> \
            </ul> \
            Other local eateries with a more relaxed atmosphere: \
            <ul> \
                <li><a href=https://gordoscubanfood.com/>Gordos (Cuban)</a></li> \
                <li><a href=https://www.peppersmexicancantina.com/>Peppers (Mexican)</a></li> \
                <li><a href=http://iwctallahassee.com/>Islands Wing Co (beach themed sports bar)</a></li> \
                <li><a href=https://www.momospizza.com/>Momo’s Pizza (slices as big as your head!)</a></li> \
                <li><a href=https://www.tazikis.com/location/tallahassee>Tazikis (Mediterranean, chain, walking distance from Springhill Suites)</a></li> \
                <li><a href=https://www.islandfinpoke.com/location/tallahassee-fl/>Island Fin Poke (Poke bowls, also walking distance)</a></li> \
                <li><a href=https://www.firstwatch.com/>First Watch (brunch, walking distance)</a></li> \
            </ul> \
            Other chains near by: Bone Fish, Outback Steak House, Ted’s Montana Grill, Chicken Salad Chicks, Newks \
            ",
        },
        {
            id: 4,
            question: "What grocery stores are nearby?",
            answer: "Trader Joes, Publix, Fresh Market",
        },
        {
            id: 5,
            question: "Where is the ceremony taking place?",
            answer: "At Pebble Hill Plantation in Thomasville, GA.  It is large and spacious venue with several historic buildings. The ceremony will be taking place by the big oak tree in front of the main house. Guests should expect to walk on a gravel pathway onto a beautiful lawn under the tree. \
            <a href=https://pebblehill.com/>https://pebblehill.com/</a> \
            "
        },
        {
            id: 6,
            question: "What happens after the ceremony?",
            answer: "Guests will mingle back at the stables courtyard where a cocktail hour will be held.\
               <br/>The wedding party will be getting their pictures taken during this time."
        },
        {
            id: 7,
            question: "Where is the reception taking place?",
            answer: "Following cocktail hour the reception will also take place at the courtyard.  It is a lovely open space with several gorgeous trees.  \
            <br/>Dinner will be served as a buffet style meal. \
            "
        },
        {
            id: 8,
            question: "Is there a dress code?",
            answer: "Yes! Sorry, no scrubs, white coats, crocs, speedos, or T-shirts you got for free from work. \
            We recommend slacks, dinner jackets or suits, cocktail dresses, skirts and blouses. The event is planned to be outdoors; so remember that when choosing footwear. \
            It may get a little cool in the evening. \
            <strong>Ladies, white in all its shades in not permitted.</strong>  \
            The bridal party will set off an alarm and proceed to color your dress in a lovely shade of red wine.\
            "
        },
        {
            id: 9,
            question: "What happens if it rains?",
            answer: "The ceremony and reception will be moved indoors at the same location.  \
            We will be emailing and texting guests about this change. \
            It will also be posted in our website. The show must go on!"
        },
        {
            id: 10,
            question: "Are kids welcome?",
            answer: "We love kids! But we respectfully request that this night be reserved for the adults in the family. \
            If you need help finding a babysitter or have any other concerns, please send us an email or text.   \
            <br/>Some resources: \
            <ul><li><a href=https://www.seminolesitters.com/rates-services>Seminole Sitters</a></li> \
            <li><a href=https://twinkletoesnanny.com/tallahassee>Twinkle Toes</a></li> \
            <li><a href=https://www.care.com/>Care.com</a></li> \
            </ul><br/> \
            Other local sitters that we've used may be available and we can share their contact info if needed. \
            "
        },
        {
            id: 11,
            question: "What if I have food allergies, preferences or celiac?",
            answer: "Our lovely caterer will accommodate special requests. Please let us know via email or text and we will keep you safe from gluten, animal products and nuts.",
        },
        {
            id: 12,
            question: "What kind of wedding will it be?",
            answer: "It's not really anything out of the ordinary.  We are non-denominational and \
            just have a simple ceremony for us to exchange our vows."
        },
    ];

    return (
        <>
            <Typography
                variant="h2"
                sx={{color: "black"}}
            >
                Questions and Answers
            </Typography>
            <Paper sx={{
                    color:'black',
                    backgroundColor: 'floralwhite',
                    display: 'inline-block',
                }}>
                {qadata.map((qa) => {
                    return (
                        <Accordion key = {`qa-${qa.id}`} sx={{margin: "10px"}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography align="left">{qa.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography align='left'>
                                <div dangerouslySetInnerHTML={{ __html: qa.answer}}></div>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}
                )}
            </Paper>
        </>
    );
}

export default QAPage;