import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as services from 'services';

const RsvpStatusUpdateModal = (props) => {

    const { open, setOpen, userInfo, setUserInfo } = props;

    const [ editUserInfo, setEditUserInfo ] = useState({...userInfo});

    const rsvpStatusToEditValue = (rsvpStatus) => {

        if (!rsvpStatus) return "NA";

        switch (rsvpStatus) {
            case "Y":
            case "y":
                return "Y";
            case "N":
            case "n":
                return "N";
            default:
                return "NA";
        }
    }

    const [ rsvpStatusEdit, setRsvpStatusEdit ] = useState(rsvpStatusToEditValue(userInfo?.rsvpStatus));

    const [ rsvpStatusFoodOptions, setRsvpStatusFoodOptions] = useState(userInfo?.rsvpStatusFoodOption ?? "");

    useEffect(() => {
        if (userInfo) {
            setEditUserInfo({...userInfo});
            setRsvpStatusEdit(rsvpStatusToEditValue(userInfo.rsvpStatus));
            setRsvpStatusFoodOptions(userInfo.rsvpStatusFoodOption ?? "" );
        }

    }, [userInfo]);


    const handleCancel = (e, reason) => {
        setOpen(false);
    }
    
    const handleClose = async (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        
        const response = await services.updateProfile(editUserInfo);
        const newUserInfo = {...response.data.updatedItem};

        setUserInfo(newUserInfo);
        setOpen(false);
    }

    const handleChange = (e) => {
        editUserInfo[e.target.id] = e.target.value;
        setEditUserInfo({...editUserInfo});
    }

    const handleRsvpUpdate = (e) => {
        setRsvpStatusEdit(e.target.value);        
        setEditUserInfo({...editUserInfo, rsvpStatus: e.target.value});
    }

    const handleRsvpFoodOptionUpdate = (e) => {
        setRsvpStatusFoodOptions(e.target.value);
        setEditUserInfo({...editUserInfo, rsvpStatusFoodOption: e.target.value})
    }

    return (
        <div>
            <Dialog 
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>RSVP Update</DialogTitle>
                <DialogContentText 
                     margin={"0px 15px 15px 15px"}
                >
                    Update RSVP Status by March 22nd, 2023.<br/>
                    We respectfully ask just to limit to just 1 additional guest                    
                    <br/>
                    Please note guest name and food preference in the notes below.
                </DialogContentText>
                <Grid container spacing={2} margin={"0px 5px 0px 5px"}>
                    <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">RSVP</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={rsvpStatusEdit}
                            name="radio-buttons-group"
                            onChange={handleRsvpUpdate}
                        >
                            <FormControlLabel value="Y" control={<Radio />} label="Yes! I will be happy to attend." />
                            <FormControlLabel value="N" control={<Radio />} label="No.  I regretfully will not be attending." />
                            <FormControlLabel value="NA" control={<Radio />} label="Unsure." />
                        </RadioGroup>
                    </FormControl>
                    {rsvpStatusEdit === "N" && 
                        <TextField
                            autoFocus
                            required
                            id="rsvpStatusReason"
                            label="Message"
                            fullWidth
                            value={editUserInfo.rsvpStatusReason ?? ""}
                            onChange={handleChange}
                        >
                                
                        </TextField>
                    }       
                    {rsvpStatusEdit === "Y" && 
                        <>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label-food">Food Preference</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label-food"
                                value={rsvpStatusFoodOptions}
                                name="radio-buttons-group"
                                onChange={handleRsvpFoodOptionUpdate}
                            >
                                <FormControlLabel value="BEEF" control={<Radio />} label="Beef" />
                                <FormControlLabel value="CHICKEN" control={<Radio />} label="Chicken" />
                                <FormControlLabel value="VEG" control={<Radio />} label="Vegatarian" />
                                <FormControlLabel value="" control={<Radio />} label="Undecided" />
                            </RadioGroup>
                        </FormControl>
                            <div>
                                Add Notes and/or Guest information below: <br/>
                                (limit to 1 additional guest)
                            </div>
                            <div>
                                <TextField
                                    //sx={{margin: '5,5,5,0'}}
                                    autoFocus
                                    required
                                    id="rsvpStatusNote"
                                    label="Note"
                                    fullWidth
                                    multiline
                                    value={editUserInfo.rsvpStatusNote ?? ""}
                                    onChange={handleChange}
                                >
                                </TextField>
                            </div>
                        </>  
                    }       
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleClose}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RsvpStatusUpdateModal;