import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import ProfileEditModal from "./ProfileEditModal";
import { rsvpFoodToDisplay, rsvpStatusToDisplay } from "../Utils";
import RsvpStatusUpdateModal from "./RsvpStatusUpdateModal";
import QRCodeModal from "./QRCodeModal";

const Profile = (props) => {

    const { userInfo, setUserInfo } = props;
    const [ openProfileEdit, setOpenProfileEdit ] = useState(false);
    const [ openRsvpEdit, setOpenRsvpEdit ] = useState(false);
    const [ openQRCodeModal, setOpenQRCodeModal ] = useState(false);
    const [ qrCodeProfile, setQrCodeProfile ] = useState(null);

    console.log(userInfo);

    const Address = (props) => {
        const { userInfo } = props;
        let addressDisplay = null;
        if (userInfo && userInfo.address  && userInfo.address.line1) {
            addressDisplay = 
                <Box display={'flex'}>
                    <span style={{fontSize: 'smaller'}}>
                        {userInfo.address.line1}
                    </span>
                </Box>;
            if (userInfo.address.Line2) {
                addressDisplay = 
                <>{addressDisplay} 
                    <Box display={'flex'}>
                        <span style={{fontSize: 'smaller'}}>
                            {userInfo.address.line2}
                        </span>
                    </Box>
                </>;
            }
            addressDisplay = 
                <>
                    {addressDisplay}
                    <Box display={'flex'}>
                        <span style={{fontSize: 'smaller'}}>
                            {userInfo.address.city}, {userInfo.address.state} {userInfo.address.zip}
                        </span>
                    </Box>
                </>
            if (userInfo.address.country && userInfo.address.country !== "US") {
                addressDisplay = 
                    <>
                        {addressDisplay} 
                        <Box display={'flex'}>
                            <span style={{fontSize: 'smaller'}}>
                                {userInfo.address.country}
                            </span>
                        </Box>
                    </>
            }
        }
        return addressDisplay;
    }

    const Field = (props) => {
        const {label, value} = props;
        return (
            <>
                <Box display={'flex'}>
                    <span style={{fontSize: 'small'}}>
                        {label}
                    </span>
                </Box>
                <Box display={'flex'}>
                    <span style={{fontSize: 'smaller'}}>
                        {value}
                    </span> 
                </Box>
            </>
        );
    }

    const handleProfileUpdateRequest = () => {
        setOpenProfileEdit(true);
    }

    const handleRsvpUpdateRequest = () => {
        setOpenRsvpEdit(true);
    }

    const handleOpenQRCode = (member_id) => {

        const member = userInfo.otherGroupMembers.find((p) => p.id === member_id);

        setQrCodeProfile({...member});
        setOpenQRCodeModal(true);
    }

    return (
        <div>
            <Button onClick={handleRsvpUpdateRequest}>RSVP</Button>
            <span style={{color: "black"}}>|</span>
            <Button onClick={handleProfileUpdateRequest}>Update Profile</Button>
            <div>
                <Paper sx={{
                    color:'black',
                    backgroundColor: 'floralwhite',
                    display: 'inline-flex',
                }}>
                    <Grid container
                        spacing={2}
                        sx={{
                            display: 'block',
                            padding: '32px',
                        }}>

                        <Grid item xs={12}>
                            <Field label={"Name:"} value={userInfo.name}/>
                        </Grid>
                        <Grid item>
                            <Field label={"RSVP Status:"} value={rsvpStatusToDisplay(userInfo)}/>
                        </Grid>
                        <Grid item>
                            <Field label={"RSVP Food Option:"} value={rsvpFoodToDisplay(userInfo)}/>
                        </Grid>
                        <Grid item>
                            <Field label={"RSVP Note:"} value={userInfo.rsvpStatusNote || userInfo.rsvpStatusReason}/>
                        </Grid>
                        <Grid item>
                            <Box display={'flex'}>
                                <span style={{fontSize: 'small'}}>
                                    Address:  
                                </span>
                            </Box>
                            <Address userInfo={userInfo} />
                        </Grid>
                        <Grid item>
                            <Field label={"Phone:"} value={userInfo.phone}/>
                        </Grid>
                        <Grid item>
                            <Field label={"Email:"} value={userInfo.email}/>
                        </Grid>

                        {/* GROUP MEMBERS */}
                        {userInfo.otherGroupMembers && userInfo.otherGroupMembers.length &&
                            <Grid item pt={16}>
                                <Typography variant="h5">
                                    Other Group Members:
                                </Typography>
                            </Grid>
                        }
                        {userInfo.otherGroupMembers && userInfo.otherGroupMembers.length 
                         && userInfo.otherGroupMembers.map((member) => {
                            if (member.isAdmin || member.id === userInfo.id) return null;
                            return (
                                <>
                                    <Grid item pt={4}>
                                        <Box display={'flex'}>
                                            <span style={{fontSize: 'smaller'}}>
                                                {member.name}
                                            </span>
                                        </Box>
                                        <Box display={'flex'} ml={2}>
                                            <span style={{fontSize: 'small'}}>
                                                RSVP: {rsvpStatusToDisplay(member)}
                                            </span>
                                        </Box>
                                        <Box display={'flex'} ml={2}>
                                            <span style={{fontSize: 'small'}}>
                                                Food Preference: {rsvpFoodToDisplay(member)}
                                            </span>
                                        </Box>
                                        <Box display={'flex'} ml={2}>
                                            <span style={{fontSize: 'small'}}>
                                                Invite Code: <Button 
                                                sx={{display:'contents', fontSize:'small', padding:'0px'}}
                                                onClick={(e) => {handleOpenQRCode(e.currentTarget.value)}}
                                                value={member.id}
                                                >{member.inviteCode}</Button>
                                            </span>
                                        </Box>
                                    </Grid>
                                </>
                            )   
                         })}
                    </Grid>
                </Paper>
            </div>
            <Button onClick={handleRsvpUpdateRequest}>RSVP</Button>
            <span style={{color: "black"}}>|</span>
            <Button onClick={handleProfileUpdateRequest}>Update Profile</Button>
            <ProfileEditModal
                open={openProfileEdit}
                setOpen={setOpenProfileEdit}
                userInfo={userInfo}
                setUserInfo={setUserInfo}>
            </ProfileEditModal>
            <RsvpStatusUpdateModal
                open={openRsvpEdit}
                setOpen={setOpenRsvpEdit}
                userInfo={userInfo}
                setUserInfo={setUserInfo}>
            </RsvpStatusUpdateModal>
            <QRCodeModal
                open={openQRCodeModal}
                qrCodeProfile={qrCodeProfile}
                setOpen={setOpenQRCodeModal}
                >

            </QRCodeModal>
        </div>    
    );
}

export default Profile;
